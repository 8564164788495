import axios from 'axios';

const api_url = process.env.VUE_APP_ROOT_API + 'api/';

export default {
    name: 'Genders',
    get_genders() {
        return axios.get(api_url + 'genders', { validateStatus: false });
    }
}
