import axios from 'axios';

const api_url = process.env.VUE_APP_ROOT_API + 'api/';

export default {
    name: 'Cities',
    get_cities(state_id) {
        return axios.get(api_url + 'cities/' + state_id, { validateStatus: false });
    }
}
