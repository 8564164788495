import axios from 'axios';

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.validateStatus = false;

export default {
    name: 'Viacep',
    get_cep(cep) {
        return axios.get('https://viacep.com.br/ws/'+cep+'/json/');
    },
}
