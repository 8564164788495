<template>
  <HeaderHome/>
  <div class="container-fluid body">
    <div class="container">
      <Loader :show="show_loader"/>
      <div class="row body-box">
        <div class="col-md-8 col-md-offset-2">
          <h1>Cadastre-se</h1>
          <p class="text-justify">
            Preencha os dados abaixo para criar uma conta na Ticket Experience.
            Com o seu usuário e senha em mãos, você consegue se cadastra em um
            evento com poucos cliques.
          </p>

          <div class="container-fluid form-box2">
            <div class="row form-group">
              <div class="col-xs-12">
                <ul class="nav nav-pills nav-justified thumbnail setup-panel">
                  <li :class="current_step === 1 ? 'active' : ''">
                    <a href="#step-1" @click.prevent="goto_step1">
                      <h4 class="list-group-item-heading">Passo 1</h4>
                      <p class="list-group-item-text">Dados Pessoais</p>
                    </a>
                  </li>
                  <li :class="[ step2 ? '' : 'disabled', current_step === 2 ? 'active' : '', ]">
                    <a href="#step-2" @click.prevent="goto_step2">
                      <h4 class="list-group-item-heading">Passo 2</h4>
                      <p class="list-group-item-text">Dados complementares</p>
                    </a>
                  </li>
                  <li :class="[ step3 ? '' : 'disabled', current_step === 3 ? 'active' : '', ]">
                    <a href="#step-3" @click.prevent="goto_step3">
                      <h4 class="list-group-item-heading">Passo 3</h4>
                      <p class="list-group-item-text">
                        Finalização do Cadastro
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <form ref="formstep1" @submit.prevent>
              <div v-show="current_step === 1" class="row setup-content">
                <div class="col-xs-12">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <h3>Dados Pessoais</h3>
                      </div>
                      <div class="col-md-6 text-right padding-h3">
                        * Campos obrigatórios.
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Nome *</label>
                        <input v-model="usuario.first_name" class="form-control" required="" type="text"/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Sobrenome *</label>
                        <input v-model="usuario.last_name" class="form-control" required="" type="text"/>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Data de nascimento *</label>
                        <input v-model="usuario.birthday" v-mask="'##/##/####'" autocomplete="off" class="form-control" maxlength="10" placeholder="__/__/____" required="" type="text"/>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>CPF *</label>
                        <input v-model="usuario.cpf" v-mask="'###.###.###-##'" autocomplete="off" class="form-control" maxlength="14" placeholder="___.___.___-__" required="" type="text" @focusout="ValidCPF"/>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Sexo *</label>
                        <select v-model="usuario.gender_id" class="form-control" required>
                          <option value="">Selecione Sexo</option>
                          <option v-for="gender in genders" :key="gender.id" :value="gender.id">
                            {{ gender.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label>E-mail *</label>
                        <input v-model="usuario.email" class="form-control" required="" type="email"/>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Senha *</label>
                        <input v-model="usuario.password" class="form-control" required="" type="password"/>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Confirmar senha *</label>
                        <input v-model="usuario.password_confirmation" class="form-control" required="" type="password"/>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <label>
                        <input v-model="usuario.terms" type="checkbox"/>
                        Concordo e aceito os termos de política e privacidade da
                        TKX
                      </label>
                    </div>
                    <div class="col-md-12">
                      <label>
                        <input v-model="usuario.receive_news" type="checkbox"/>
                        Desejo receber o calendário Tkx, dicas e oportunidades de
                        parceiros.
                      </label>
                    </div>
                    <div class="col-md-12 text-right">
                      <button class="btn btn-success" @click.prevent="next_step2">
                        Próximo Passo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <form ref="formstep2" @submit.prevent>
              <div v-show="current_step === 2" class="row setup-content">
                <div class="col-xs-12">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <h3>Dados complementares</h3>
                      </div>
                      <div class="col-md-6 text-right padding-h3">
                        * Campos obrigatórios.
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>E-mail secundário</label>
                        <input v-model="usuario.email_second" class="form-control" type="email"/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Telefone</label>
                        <input v-model="usuario.telephone" v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="off"
                               class="form-control" maxlength="15" type="text"/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Celular *</label>
                        <input v-model="usuario.cellphone" v-mask="['(##) ####-####', '(##) #####-####']" required=""  autocomplete="off" class="form-control" maxlength="15" type="text"/>
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <button class="btn btn-success" @click.prevent="next_step3">
                        Próximo Passo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <form ref="formstep3" @submit.prevent>
              <div v-show="current_step === 3" class="row setup-content">
                <div class="col-xs-12">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <h3>Dados complementares</h3>
                      </div>
                      <div class="col-md-6 text-right padding-h3">
                        * Campos obrigatórios.
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label>CEP *</label>
                        <input v-model="usuario.cep" required="required" class="form-control"  v-mask="['#####-###']"  type="text"  @focusout="force_cep"/>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Endereço *</label>
                        <input v-model="usuario.street" class="form-control" required="" type="text"/>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Número *</label>
                        <input v-model="usuario.number" class="form-control" required="" type="text"/>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Complemento</label>
                        <input v-model="usuario.complement" class="form-control" type="text"/>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Bairro *</label>
                        <input v-model="usuario.neighborhood" class="form-control" required="" type="text"/>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Estado *</label>
                        <select v-model="usuario.state_id" class="form-control" required @change="change_state">
                          <option value="">Selecione um Estado</option>
                          <option v-for="state in states" :key="state.id" :value="state.id">
                            {{ state.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Cidade *</label>
                        <select v-model="usuario.city_id" :disabled="cities.length === 0" class="form-control" required>
                          <option value="">Selecione uma Cidade</option>
                          <option v-for="(city, index) in cities" :key="index" :value="index">
                            {{ city }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-12 text-right">
                      <button class="btn btn-success" @click="sign_up" @click.prevent="next_step3">
                        Efetuar Cadastro
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </form>

          </div>
          <Atalhos/>
        </div>
      </div>
    </div>
  </div>
  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<script>
import HeaderHome from "@/components/HeaderHome";
import Footer from "@/components/Footer";
import FooterEnd from "@/components/FooterEnd";
import Newsletter from "@/components/Newsletter";
import Atalhos from "../components/Atalhos";
import {mask} from "vue-the-mask";
import States from "@/api/States";
import Cities from "@/api/Cities";
import Genders from "@/api/Genders";
import User from "@/api/User";
import Auth from "../api/Auth";
import Loader from "../components/Loader";

import Swal from "sweetalert2";
import Viacep from "@/api/Viacep";

export default {
  directives: {mask},
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Newsletter,
    Atalhos,
    Loader
  },
  data() {
    return {
      step1: true,
      step2: false,
      step3: false,
      show_loader: false,
      current_step: 1,
      states: [],
      cities: [],
      genders: [],
      usuario: {
        cep: "",
        state_id: "",
        city_id: "",
        gender_id: "",
        first_name: "",
        last_name: "",
        email: "",
        email_second: "",
        password: "",
        password_confirmation: "",
        cpf: "",
        birthday: "",
        telephone: "",
        cellphone: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        receive_news: false,
      },
    };
  },
  methods: {
    force_cep() {
      if (this.usuario.cep.length != 9) {
        this.usuario.cep = "";
      }else {
        Viacep.get_cep(this.usuario.cep).then((resp) => {

          this.usuario.street = resp.data.logradouro;
          this.usuario.complement = resp.data.complemento;
          this.usuario.neighborhood = resp.data.bairro;

         let State = this.states.find((ev) => ev.uf === resp.data.uf );
         if(State){
           this.usuario.state_id = State.id;
           this.change_state(resp.data.localidade);
         }


        })
      }
    },
    goto_step1() {
      this.step1 = true;
      this.current_step = 1;
    },
    goto_step2() {
      if (this.step2 === true) {
        this.current_step = 2;
      }
    },
    goto_step3() {
      if (this.step3 === true) {
        this.current_step = 3;
      }
    },
    next_step2() {
      if (this.$refs.formstep1.checkValidity() === false) {
        this.$refs.formstep1.reportValidity();
        return false;
      }

      if(this.usuario.password !== this.usuario.password_confirmation){
        alert('Confirmação de senha não coincide com a senha.')
        return false;
      }

      this.step2 = true;
      this.current_step = 2;
    },
    next_step3() {
      if (this.$refs.formstep2.checkValidity() === false) {
        this.$refs.formstep2.reportValidity();
        return false;
      }

      this.step3 = true;
      this.current_step = 3;
    },
    change_state(set_city='') {
      if (this.usuario.state_id === "") {
        this.cities = [];

        this.usuario.city_id = "";
        return false;
      }

      this.usuario.city_id = "";
      Cities.get_cities(this.usuario.state_id).then((request) => {
        if (request.status === 200) {
          this.cities = request.data;

          if(set_city){
            let cidade_id = Object.values(request.data).indexOf(set_city);
            if(cidade_id >-1){
              this.usuario.city_id = Object.keys(request.data)[cidade_id];
            }
          }
        }
      });

    },
    ValidCPF() {
      let valid = this.isValidCPF(this.usuario.cpf);
      if (valid === false) {
        this.usuario.cpf = "";
      }
    },
    isValidCPF(cpf) {
      if (typeof cpf !== 'string') return false
      cpf = cpf.replace(/[^\d]+/g, '')
      if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
      cpf = cpf.split('')
      const validator = cpf
          .filter((digit, index, array) => index >= array.length - 2 && digit)
          .map(el => +el)
      const toValidate = pop => cpf
          .filter((digit, index, array) => index < array.length - pop && digit)
          .map(el => +el)
      const rest = (count, pop) => (toValidate(pop)
          .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) % 11 % 10

      return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])
    },
    sign_up() {
      if (this.$refs.formstep3.checkValidity() === false) {
        this.$refs.formstep3.reportValidity();
        return false;
      }

      this.show_loader = true;
      let signup = User.registration(Object.assign({}, this.usuario));

      signup.then((requestCreate) => {
        if (requestCreate.status === 201) {
          User.login(this.usuario.email, this.usuario.password).then((request) => {
            if (request.status === 200) {
              Auth.set_token(request.data.access_token);
              User.profile(request.data.access_token).then((request2) => {
                if (request2.status === 200) {
                  Auth.set_user(request2.data);

                  this.show_loader = false;
                  this.$router.push("/escolha-o-kit/" + this.id + "/" + this.slug + "/");
                  window.scrollTo(0, 0);
                } else {
                  this.show_loader = false;
                }
              });
            } else {
              this.show_loader = false;
            }
          });
        } else {
          if (requestCreate.status === 422) {
            let MSGS = "";
            let errors = [];
            errors = requestCreate.data.errors;
            Object.entries(errors).map((value) => {
              MSGS = value[1][0] + "<br>" + MSGS;
            });
            Swal.fire({
              icon: 'error',
              title: 'Alguns campos apresentam erro',
              html: MSGS,
              showConfirmButton: true,
            });

            this.goto_step1();
          }
          this.show_loader = false;
        }
      });
    },
  },
  mounted() {
    let states = States.get_states();

    states.then((request) => {
      if (request.status === 200) {
        this.states = request.data;
      }
    });

    let genders = Genders.get_genders();

    genders.then((request) => {
      if (request.status === 200) {
        this.genders = request.data;
      }
    });
  },
};
</script>

<style>
.padding-h3 {
  padding: 10px 0 15px 0;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.setup-panel a {
  color: #ac2925;
}
</style>
